.wrapper-components {
    position: relative;
    height: calc(100vh - 70px);
    display: flex;
    align-items: center;
}

.div-controls {
    height: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

.header-container .header-title,
.header-options {
    background-color: white;
}

.loading-jitsi {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
}

.wrapper-left {
    position: relative;
    width: 75%;
    height: calc(100vh - 50px);
}

.wrapper-right {
    display: flex;
    flex-wrap: wrap;
    width: 25%;
    height: 100%;
}

#sp_embed {
    height: 100%;
    width: auto;
}

#jitsi-container {
    position: relative;
    z-index: 20;
    height: 100%;
}

#jitsi-container ._html5Player {
    max-width: 1000px !important;
    height: 100% !important;
}

@media (max-width: 600px) {
    .wrapper-container:last-child {
        height: calc(100vh - 420px) !important;
    }
    /** chat container */
    .wrapper-container:first-child {
        height: 300px !important;
        order: 1;
        position: relative;
        z-index: -1;
        -webkit-box-ordinal-group: 2;
    }

    .wrapper-components {
        flex-direction: column;
    }

    .wrapper-left {
        height: 300px;
        width: 100%;
        z-index: 10;
    }

    #jitsi-container {
        height: 280px;
    }

    .wrapper-right {
        width: 100%;
        height: auto;
    }
}
