input[type='password'] {
    font-family: inherit;
}

.error-message {
    height: 20px;
}

.error-message p {
    font-size: 14px;
    margin: 0;
    text-align: center;
    font-weight: bold;
    color: #999;
}

.div-tests-button {
    width: 300px;
    margin-bottom: 1em;
}

.wrapper-midline {
    width: 100%;
    height: 0.6em;
    border-bottom: 1px solid #999;
    text-align: center;
}

.wrapper-midline span {
    font-size: 1em;
    background-color: white;
    padding: 0 10px;
}

.form-modal {
    padding: 0 3em;
}

.login-inputs {
    margin: 10px 0;
}

.title-welcome {
    position: relative;
    text-align: center;
    color: var(--main-bg-button-primary);
    font-weight: bold;
    font-family: 'Roboto', sans-serif;
}

.wrapper-login {
    height: 100vh;
    background: linear-gradient(90deg, var(--gradient-secondary) 0%, var(--gradient-primary) 100%);
    background-size: contain;
    display: flex;
    align-items: center;
}

.wrapper-content {
    width: 400px;
    margin: 0 auto;
    border: 3px solid #eee;
    padding: 10px;
    border-radius: 5px;
}

.wrapper-title {
    width: 500px;
    margin: 0 auto;
}

.wrapper-welcome {
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    padding-bottom: 2em;
    width: 65%;
    height: auto;
    background: rgba(43, 52, 68, 0.5);
}

.wrapper-welcome h4 {
    margin: 0;
    color: #999;
}
.wrapper-welcome h1 {
    font-size: 4em;
    margin-top: 0;
    margin-bottom: 0;
    color: whitesmoke;
}

.logo-welcome {
    width: 65%;
    margin: 7em auto 0;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.logo-welcome img {
    height: 70px;
    margin-left: 1em;
    object-fit: contain;
}

.wrapper-login-modal {
    background-color: white;
    min-height: 300px;
    border-radius: 5px;
    padding: 1em 0;
}

.wrapper-logo {
    display: flex;
    justify-content: center;
}

.wrapper-logo img {
    height: 100px;
}

.wrapper-logo .wrapper-title {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}

.wrapper-logo .line-title {
    height: 3px;
    background-color: #ef984e;
    margin-top: 5px;
    width: 3em;
}

.wrapper-login-form {
    margin: 10px 0;
}

.wrapper-logo h3,
.wrapper-logo p {
    margin: 0;
}

.wrapper-button-actions {
    padding: 8px 0;
    text-align: center;
}

.Input {
    background: #e4ebf6;
    border: none;
    border-radius: 3px;
    font-size: 1em;
    height: 3em;
    text-indent: 1em;
    width: calc(100% - 4px);
    outline: none;
    transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
}

.Input-error {
    border-bottom: 1px solid lightcoral;
}

.form-modal {
    padding: 0 3em;
}

.link {
    text-decoration: none;
    font-style: italic;
    color: #0274a2;
    font-size: 0.8em;
}

.link:hover {
    text-decoration: underline;
}

.wrapper-login .select__control {
    background: #e4ebf6;
    border: none;
    border-radius: 3px;
    font-size: 1em;
    height: 3em;
    text-indent: 1em;
    width: 100%;
    outline: none;
    transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
}

.wrapper-login .select__control:hover,
.wrapper-login .select__control:focus {
    border: none;
}

.wrapper-login .select__value-container {
    height: 100%;
    flex-wrap: nowrap;
}

.select__placeholder {
    font-size: 14px;
}

input::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: hsl(0, 0%, 50%);
    font-size: 14px;
}
